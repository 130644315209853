import {gql} from '@apollo/client';

export const PERSONAL_STATS = gql`
    query ($clientId: String!){
        getPersonalStatsByClientIdForOwner(clientId: $clientId){
            averageTimeSpent,
            averageMoneySpent,
            moneySpent,
            numOfSessions
        }
    }
`;


export const HISTORY_VISIT = gql`

    query($clientId: String!, $isActive: Boolean, $page: Int!, $sortBy: AllowedSortingValues!){

        getVisitationsOfAClientForAdminPanel(clientId: $clientId, isActive: $isActive, page: $page, sortBy: $sortBy){
            _id, 
            price, 
            startTime, 
            endTime,
            status,
            institution {
                _id, 
                name,
                location {
                    address
                }
            },
            service {
                _id,
                price,
                bufferTimeMinutes,
                name
            }
	    }
    }

`;