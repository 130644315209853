import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ru: {
      translation: {
        admin: "Администратор",
        secretary: "Секретарь",
        enter: "Вход",
        login: "Войдите в аккаунт Time",
        email: "Электронная почта",
        password: "Пароль",
        error: "Что-то не подходит. Попробуйте ввести другой логин или пароль",
        continue: "Продолжить",
        register: "Регистрация",
        titleAdmin: "Панель управления TimeApp",
        session: "Сессии за сегодня",
        everywhere: "Везде",
        forToday: "За сегодня",
        endedSessions: "Завершенные сессии",
        activeSessions: "Активные сессии",
        placesExist: "Места есть",
        noPlace: "Мест нет",
        pickClub: "Выберите клуб",
        active: "Активные",
        completed: "Завершенные",
        searchByName: "Поиск по имени",
        noSessions:
          "Сейчас сессий нет. Как придут клиенты, сессии появятся здесь. Для актуальной информации — обновите страницу",
        titleClient: "Страница клиента",
        back: "Назад",
        averagePricePerVisit: "Средняя цена за одно посещение",
        averageTimePerVisit: "Среднее время одного посещения",
        overallSum: "Общая сумма всех посещений",
        startTime: "Время начала",
        duration: "Длительность",
        sum: "Сумма",
        end: "Завершить",
        historyVisits: "История посещений",
        recentOnesFirst: "Сначала недавние",
        byPrice: "По цене",
        byDuration: "По длительности",
        stats: "Статистика",
        year: "Год",
        month: "Месяц",
        today: "Сегодня",
        remove: "Убрать",
        pickDate: "Выбрать дату",
        sessions: "Сессий",
        revenue: "Выручка",
        min: "мин",
        jan: "Янв",
        feb: "Фев",
        mar: "Мар",
        apr: "Апр",
        may: "Май",
        jun: "Июн",
        jul: "Июл",
        aug: "Авг",
        sep: "Сен",
        oct: "Окт",
        nov: "Ноя",
        dec: "Дек",
      },
    },
    en: {
      translation: {
        admin: "Administrator",
        secretary: "Secretary",
        enter: "Enter",
        login: "Log in to your Time account",
        email: "Email",
        password: "Password",
        error:
          "Something doesn't fit. Try entering a different username or password",
        continue: "Continue",
        register: "Registration",
        titleAdmin: "TimeApp Control Panel",
        session: "Sessions for today",
        everywhere: "Everywhere",
        forToday: "For today",
        endedSessions: "Completed sessions",
        activeSessions: "Active sessions",
        placesExist: "There are places",
        noPlace: "No place",
        pickClub: "Choose a club",
        active: "Actives",
        completed: "Completed",
        searchByName: "Search by Name",
        noSessions:
          "There are no sessions right now. As soon as the clients arrive, the sessions will appear here. For up—to-date information - refresh the page",
        titleClient: "Client page",
        back: "Back",
        averagePricePerVisit: "Average price per visit",
        averageTimePerVisit: "Average time per visit",
        overallSum: "The total amount of all visits",
        startTime: "Start time",
        duration: "Duration",
        sum: "Sum",
        end: "End",
        historyVisits: "History of visits",
        recentOnesFirst: "Recent ones first",
        byPrice: "By price",
        byDuration: "By duration",
        stats: "Statistics",
        year: "Year",
        month: "Month",
        today: "Today",
        remove: "Remove",
        pickDate: "Select a date",
        sessions: "Sessions",
        revenue: "Revenue",
        min: "min",
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
      },
    },
  },
});

export default i18n;
