import {gql} from '@apollo/client';


export const VISITATIONS_FOR_ADMIN = gql`
    query ($institutionId: String, $isActive: Boolean, $startingDate: DateTime, $page: Int!) {
        getVisitationsForAdminPanel(institutionId: $institutionId, isActive: $isActive, startingDate: $startingDate, page: $page, sortBy: Date) {
            
            numOfMoney,
            numOfSessions,
            sessions { 
                _id,
                client {
                    _id,
                    fullName,
                    phoneNumber,
                    dateOfBirth,
                    email,
                    sex,
                    city,
                    photoURL {
                        M,
                        thumbnail, 
                        XL
                    }
                },
                startTime,
                endTime,
                institution {
                    _id,
                    name
                },
                service {
                    price,
                    bufferTimeMinutes,
                    name,
                    maxPrice
                },
                status,
                price
            }
        }
    }

`;