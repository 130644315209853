import styled from 'styled-components';

export const StyledSettings = styled.div`

    display: flex;

    @media (max-width: 1200px){
        justify-content: center;
    }
`;


export const Container = styled.div`

    width: 100%;
    padding: 30px;

`;

export const Main = styled.div`

    max-width: 410px;
    width: 100%;

    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;

    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        
        input {
            min-height: 45px;
            border: 0.5px solid #0F84F4;
            box-sizing: border-box;
            border-radius: 15px;
            padding: 10px;
            &:focus {
                outline: none;
            }
        }
    }

    @media (max-width: 480px){
        width: unset;
    }
`;


export const Company = styled.div`

`;

export const Input = styled.div`

`;

export const Button = styled('button')<{active: string}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    
    cursor: pointer;
    color: #F40F0F;
    ${props => props.active === 'logout' ? 'background: #FFE6E6; max-width: 470px; width: 100%;' : 'background: #007AFF; color: #fff;'}
    
    border-radius: 15px;
    border: none;
`;