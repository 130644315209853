import styled from 'styled-components';

export const StyledAdmin = styled.div`

    display: flex;
    justify-content: center;
    height: 100%;
    /* padding-top: 50px; */
    overflow: hidden;

    @media (max-width: 960px){
        overflow-y: scroll;
    }
    

    @media (max-width: 1200px) {
        /* padding-top: 40px; */
    }
`;


export const Menu = styled.div`

    width: 85px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px 20px 20px 64px;

    
    div.logout{
        width: 85px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #FFFFFF;
        box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
        border-radius: 15px;
    }

    @media (max-width: 1200px) {
        max-width: 330px;
        width: 100%;
        max-height: 65px;
        height: 100%;
        position: fixed;
        bottom: 40px;
        margin: 0;
    }

    @media (max-width: 640px) {
        max-width: 220px;
        max-height: 60px;
    }
`;


export const Main = styled.div`

    width: 80%;
    height: 100%;
    .inactive{
        display: none;
    }

    .active{
        display: block;
    }

    .shown{
        max-height: 100%;
        .institution{
            min-height: 25px;
            display: flex;
            align-items: center;
            color: #007AFF;
            font-size: 16px;
            line-height: 22px;
        }
    }

    @media (max-width: 1200px) {
    
        width: 100%;
        padding: 0 40px;
    }

    @media (max-width: 480px) {
        padding: 40px 20px 0;
    }
`;

export const UList = styled.ul`
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    
    @media (max-width: 1200px) {
    
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
    }
`;

export const List = styled.li`
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
    cursor: pointer;

    
    .activeLink {
        svg {
            fill: #007AFF;
            rect {
                fill: #007AFF;
            }
        }
    }

    &:nth-child(4) {
        padding-bottom: 35px;
    }
    /* &:nth-child(3) {
        padding-top: 20px;
        padding-bottom: 35px;
    } */

    @media (max-width: 1200px) {
    
        padding: 20px 0 !important;

        &:nth-child(4) {
            padding-bottom: 20px;
        }
    }

    @media (max-width: 640px) {

        img {
            width: 80%;
        }
    }
`;


export const Button = styled('button')<{color: string, backgroundColor: string, fontSize?: number}>`

    max-width: 400px;
    width: 100%;
    min-height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${props => props.backgroundColor !== '#FFFFFF' ? props.backgroundColor : '#FFFFFF'};
    color: ${props => props.color};
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 10px;
    border: none;

    ${props => props.fontSize !== undefined ? `font-size: ${props.fontSize}px`: ''};

    .everywhere-block {
        display: flex;
        align-items: center;
        font-size: 16px;
        gap: 10px;
        min-height: 52px;
        span {
            color: #000;
        }
    }

    .active {
        width: 100%;

        .institution {
            min-height: 52px;
            justify-content: center;
        }
    }
    @media (max-width: 1200px) {
    
        max-width: 287px;
    }

    
    @media (max-width: 960px) {
        max-width: 190px;
    }

    @media (max-width: 640px) {
        max-width: 140px;
        max-height: 55px;
    }

    @media (max-width: 480px) {
        max-width: 100% !important;
        max-height: 55px;
    }
`;

export const Header = styled.div`
    
    display: flex;
    align-items: center;
    padding-top: 15px;
    gap: 10%;
    @media (max-width: 1200px) {
        
        justify-content: space-between !important;
    }

    @media (max-width: 480px) {
        
        flex-direction: column;
        padding: 0 20px !important;
    }
`;


export const Container = styled.div`

    display: flex;
    margin-top: 40px;
    flex-direction: row-reverse;
    justify-content: center;
    height: 80%;
    gap: 20px;

    .container{

        width: 100%;


        .inactive-sessions {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            padding: 40px 60px 50px;


            background: #F9F9F9;
            border-radius: 15px;

            .inactive-header {
                max-width: 440px;
                color: #434343;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
            }
            img {
                width: 75%;
            }
        }
    }

    .search-leeds {
        display: flex;
        justify-content: space-between;
        max-height: 71px;
        width: 100%;
        height: 100%;
        gap: 10px;
        .active-completed {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50%;
            background: #F8F8F8;
            border-radius: 15px;
            padding: 10px;

            .active-block {
                width: 50%;
                max-height: 51px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #007AFF;
                cursor: pointer;

                .active {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
            .active-block .checked{
                color: #434343;
                background: #FFFFFF;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
            }

            .completed-block {

                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                max-height: 51px;
                height: 100%;
                color: #007AFF;
                cursor: pointer;
                .completed {

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                }
                
            }
            .checked{
                color: #434343;
                background: #FFFFFF;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
            }
        }
        .search {
            width: 50%;
            display: flex;
            align-items: center;
            background-color: #F8F8F8;
            border-radius: 15px;
            .search-icon {
                padding: 0 10px 0 20px;
                
            }
        }
    }

    .statistics {
        display: flex;
        flex-direction: column;
        max-width: 292px;
        width: 100%;
        gap: 6px;
        .stats-flex {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .stats-details {
            display: flex;
            align-items: center;
            background: #F8F8F8;
            border-radius: 15px;
            .stats-icon {
                padding-left: 20px;
                padding-right: 16px;
            }
            h2 {
                font-size: 36px;
                margin-bottom: 0;
                margin: 0;
                padding-top: 29px;
            }
            p {
                margin: 0;
                padding-bottom: 30px;
                color: #979797;
            }
        }

        .restrict-place {
            width: 100%;
            min-height: 65px;
            display: flex;
            align-items: center;
            background: #F8F8F8;
            border-radius: 15px;

            .place-exists {
                width: 50%;
                min-height: 51px;
                margin: 10px;
                text-align: center;
                background: #FFFFFF;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
            }

            .no-place {
                width: 50%;
                color: #F40F0F;
                text-align: center;
                &-green {
                    width: 50%;
                    text-align: center;
                    color: #006400	;
                }
            } 

            .choose-club-text {
                text-align: center;
                margin-left: 10px;
            }
        }

        .refresh {
            button {
                background-color: #007AFF;
                color: #FFF;
                cursor: pointer;
            }
        }
    }
    @media (max-width: 1200px) {
    
        justify-content: unset;
        .search {
            margin-right: 16px;
        }

        .search-leeds {

            .active-completed {
                width: 50% !important;
                max-width: 100% !important;
            }

            .search {
                width: 50% !important;
            }
        }

        .active-completed {
            max-width: 287px !important;

            .active-block {
            }
        }

        .inactive-sessions {
            .inactive-header {
                font-size: 18px !important;
                line-height: normal !important;
            }

            img {
                width: 60% !important;
            }
        }
    }

    @media (max-width: 960px) {
        
        flex-direction: column;
        .statistics {
            flex-direction: column;
            max-width: 100%;

            .stats-flex {
                display: flex;
                flex-direction: row-reverse;
            }
            .stats-details {
                width: 50%;
            }

            .refresh {
                position: absolute;
                bottom: 25px;
            }
        }
        .container {
            margin-top: 30px;
            max-width: 100%;
        }

        .search-leeds {
            flex-direction: column;
            max-height: 142px;
            .active-completed {

                max-width: 100% !important;
                min-height: 50px;
                width: unset !important;
                .active-block {
                    max-width: 50% !important;
                    width: 100%;
                }
            }
            .search {
                min-height: 71px;
                max-width: 100%;
                width: 100% !important;
                margin: 0;
            }
        }
    }

    @media (max-width: 480px) {
        .statistics {
            flex-direction: column;
            .stats-flex {
                flex-direction: column-reverse;
            }
            .stats-details {
                width: 100%;
            }
            .refresh {
                top: 25px;
                right: 25px;
            }
        }
        .search-leeds {
            .active-completed {
                max-width: 100% !important;
                width: unset;
            }
        }
        .inactive-sessions {
            padding: 40px 40px 50px !important;
            img {
                width: 85% !important;
            }
        }
    }
`;

export const Input = styled.input`

    width: 100%;
    font-size: 18px;
    background: #F8F8F8;
    border-radius: 15px;
    border: none;

    &:focus {
        outline: none;
    }
`;



export const Data = styled.div`

    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding: 20px;
    background: #F9F9F9;
    border-radius: 15px;
    height: 85%;
    overflow-y: scroll;

    .reload-page {

        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        color: #434343;

    }

    @media (max-width: 960px) {

        margin-right: 0 !important;
        overflow: hidden;
        height: auto;
    }
`;


export const DataComponent = styled.div`

    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    cursor: pointer;

    .personal-data {
        display: flex;
        align-items: center;
        gap: 10%;
        width: 100%;

        .data {
            display: flex;
            min-width: 260px;
        }
        .client-name {
            max-width: 170px;
            overflow: auto;
            p {
                color: #979797;
                margin: 0;
            }
            h2{
                margin: 0;
                text-overflow: ellipsis;
            }

        }

        .instituion-name {
            display: flex;
            padding-left: 20px;
            gap: 40px;
            color: #434343;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;

            .short-id {
                min-width: 110px;
            }
        }
    }

    .company-name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        color: #979797;
        .institution-name-header {
            visibility: hidden;
        }

        .start-time {

            padding-right: 15px;
        }
    }
    .leed {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;
    }
    .time-spent {
        margin: 0 15px 0 0;
        h2 {
            display: inline;
            padding: 0;
            margin: 0;
            align-content: flex-end;
        }

        div.money-spent {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            div{
                text-align: right;
            }
        }
    }

    .time-spent-responsive {
        display: none;
    }
    .leed-show {
        width: 100%;
    }
    @media (max-width: 1200px) {
        
        .institution-name-header {
            span {
                visibility: visible;
                padding-left: 15px;
            }
        }

        .instituion-name {
            display: none !important;
        }
    }

    @media (max-width: 480px){
        max-width: 95%;        
        margin: 10px 10px 0 10px;
    }

    @media (max-width: 480px){
        
        flex-direction: column;

        .leed {
            flex-direction: column;
            padding: 0;
            .personal-data {
                width: 100%;

                .data {
                    width: 100%;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            }
        }

        .company-name {
            margin: 0;
            flex-direction: column;

            div {
                padding-left: 15px;

                span {
                    padding: 0;
                }
            }
        }
        .time-spent-responsive{
            display: flex;
            width: 100%;

            padding-top: 10px;
            padding-bottom: 15px;

            .money-spent {
                width: 100%;
                display: flex;
                justify-content: space-between;

                div {
                    width: 50%;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #F8F8F8 !important;
                    border-radius: 6px;
                    margin: 0 10px;
                    h2 {
                        margin: 0;
                        text-align: center;
                        font-size: 28px;
                    }
                }
            }
        }

        .time-spent {
            display: none;
        }
        .leed-show {
            display: flex;
            flex-direction: column-reverse;
            padding-top: 15px;

            .leed {

                .personal-data {
                    .data {
                        .client-name {
                            padding-left: 15px;

                            p{
                                color: #434343;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const NameCapital = styled('div')<{active: boolean, fontSize?: number, minWidth?: number, minHeight?: number}>`

    min-width: ${props => props.minWidth !== undefined ? props.minWidth + 'px' : '53px'} ;
    min-height: ${props => props.minHeight !== undefined ? props.minHeight + 'px' : '53px'};
    max-height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 15px;
    color: #fff;
    border-radius: 50%;
    font-size: 36.3427px;
    font-size: ${props => props.fontSize !== undefined ? props.fontSize + 'px' : '36.3427px'};
    font-weight: bold;
    line-height: 44px;
    text-align: center;
    background-color: ${props => props.active ? '#00E440': 'red'};

    @media (max-width: 480px) {
        margin-right: 15px;
    }
`;