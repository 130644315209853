import {gql} from '@apollo/client';

export const SET_AVAILABILITY = gql`
    mutation($institutionId: String!){
        setAvailabilityOfInstitution(institutionId: $institutionId){
            _id,
            name,
            city,
            averagePrice,
            isAvailable
        }
    }
`;