import {gql} from '@apollo/client';


export const GET_COMMENTS = gql`

    query($institutionId: String!, $page: Int!){
        getCommentsOfAInstitution(institutionId: $institutionId, page: $page){
            _id, 
            client {
                _id,
                fullName,
                photoURL {
                    M,
                    XL,
                    thumbnail
                }
            },
            dateAdded,
            rating,
            text
        }
    }

`;