import {gql} from '@apollo/client';


export const GET_HISTORY = gql`
    query(

        $institutionId: String
        $page: Int!

    ){

        getHistoryOfVisitations(institutionId: $institutionId, page: $page){
            _id, 
            client {
                _id,
                fullName, 
                phoneNumber, 
                dateOfBirth,
                email,
                sex,
                city,
                photoURL {
                    M,
                    thumbnail, 
                    XL
                }
            },
            institution {
                _id,
                name,
                location {
                    address
                },
            }
            service {
                _id,
                bufferTimeMinutes,
                name

            }
            startTime, 
            status,
            endTime, 
            price
        }
    }

`;



export const GET_STATS_BY_DATES = gql`

    query (
        $firstDate: DateTime!
        $secondDate: DateTime!
        $separateBy: AllowedStatsDateTypes!
        $institutionIds: [String!]
    )
    {
        getStatsByDates(firstDate: $firstDate, secondDate: $secondDate, separateBy: $separateBy, institutionIds: $institutionIds) {
            dates {
                dateType,
                value
            },
            earnings,
            numOfSessions
        }
    }
`;


export const GET_INST = gql`

    {
        getInstitutionsOfOwner{
            _id,
            name,
            location {
                address
            },
        }

    }

`;