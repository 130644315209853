import { useState, useEffect, useRef } from "react";
import {
  StyledAdmin,
  Main,
  Menu,
  Button,
  Header,
  Container,
  Input,
} from "../styled/Admin";
import { Visitations, InstitutionType } from "../interfaces/Visitations";
import DataItem from "../component/DataItem";
import {
  useLazyQuery,
  useSubscription,
  useQuery,
  useMutation,
} from "@apollo/client";
import { SET_AVAILABILITY } from "../gql/mutation/setAvailability";
import forward_up from "../assets/icons/forward-up.svg";
import forward_down from "../assets/icons/forward-down.svg";
import bar from "../assets/icons/barchart.svg";
import threePeople from "../assets/icons/threepeople.svg";
import search from "../assets/icons/search.svg";
import inactive from "../assets/images/inactive.png";
import { VISITATIONS_FOR_ADMIN } from "../gql/query/visitationsForAdminPanel";
import { VIEW_VISITATIONS } from "../gql/subscription/viewVisitations";
import { getVisitations } from "../interfaces/DataItem";
import MenuComponent from "../component/MenuComponent";
import { onScroll } from "../utils/onScroll";
import {
  InstituitionOfOwner,
  InstituitionObject,
} from "../interfaces/Instituion";
import { GET_INST_OWNER } from "../gql/query/getInstitutionsOfOwner";
import { getFromLocalStorage } from "../gql/localStorage/store";
import React from "react";
import { useTranslation } from "react-i18next";

interface ViewVisitations {
  viewVisitations: Visitations;
}

interface ISetAvailability {
  setAvailabilityOfInstitution: {
    _id: string;
    name: string;
    city: string;
    averagePrice: number;
    isAvailable: boolean;
  };
}

const Admin = () => {
  const { i18n, t } = useTranslation();

  const [isActive, setActive] = useState(true);

  const institutionRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState("all");
  const [institutions, setInstitutions] = useState<InstituitionObject[]>([]);

  const [pickedInst, setPickedInst] = useState<InstituitionObject | null>(null);

  const [sessions, setSessions] = useState<Visitations[]>([]);
  const [activeSessions, setActiveSessions] = useState<Visitations[]>([]);
  const [searchByName, setSearchByName] = useState("");
  const [shownInst, setShownInst] = useState(false);
  const [noPlace, setPlace] = useState(false);

  const todayDate = new Date();
  const day = todayDate.toDateString().split(" ")[2];
  const month = todayDate.getMonth();
  const year = todayDate.getFullYear();
  const refToBottom = useRef<HTMLDivElement>(null);

  const todaySession = `${day}.${
    month + 1 < 10 ? "0" + (month + 1) : month + 1
  }.${year}`;
  const [page, setPage] = useState(1);

  const today = new Date(year, month, parseInt(day), 0, 0).toISOString();

  const [visitations, { data: visData }] = useLazyQuery<getVisitations>(
    VISITATIONS_FOR_ADMIN,
    {
      variables: {
        startingDate: today,
        page: page,
        isActive: isActive,
      },
      // pollInterval: 1000
    }
  );

  const [setAval, { data: isAvailablePlace, error }] =
    useMutation<ISetAvailability>(SET_AVAILABILITY);

  const { data: instOwner } = useQuery<InstituitionOfOwner>(GET_INST_OWNER);

  const { data: subData } = useSubscription<ViewVisitations>(VIEW_VISITATIONS, {
    variables:
      name === "all"
        ? {
            institutionId: name === "all" ? "" : name,
          }
        : {
            institutionId: name,
          },
    shouldResubscribe: true,
  });

  useEffect(() => {
    const visit = visitations();
    if (visData) {
      if (!isActive) {
        sessionsState();
      } else subscriptionData();
    }
    // return () => {
    // 	clearInterval(visit);
    // }
  }, [visData, subData]);

  useEffect(() => {
    document.title = t("titleAdmin");
  }, []);

  const subscriptionData = () => {
    if (activeSessions.length !== 0) {
      for (const vis of visData!.getVisitationsForAdminPanel.sessions) {
        const res = activeSessions.filter((item) => item._id === vis._id);
        if (res.length === 0) {
          activeSessions.push(vis);
        }
      }
      setActiveSessions(() => {
        if (subData) {
          const res = activeSessions.filter(
            (item) => item._id === subData.viewVisitations._id
          );
          if (res.length) {
            if (
              subData.viewVisitations.endTime !== res[0].endTime &&
              subData.viewVisitations.status !== res[0].status &&
              subData.viewVisitations.endTime !== null
            ) {
              return activeSessions.filter(
                (item) => item._id !== subData.viewVisitations._id
              );
            }
          }
        }
        return [...activeSessions];
      });
    } else
      setActiveSessions([...visData!.getVisitationsForAdminPanel.sessions]);
  };

  useEffect(() => {
    if (instOwner) {
      if (instOwner.getInstitutionsOfOwner.length === 1) {
        setPickedInst(instOwner.getInstitutionsOfOwner[0]);
      }
      setInstitutions([...instOwner.getInstitutionsOfOwner]);
    }
  }, [instOwner]);

  useEffect(() => {
    if (pickedInst) {
      setSessions(
        sessions.filter((item) => item.institution._id === pickedInst._id)
      );
    } else {
      sessionsState();
    }
  }, [pickedInst]);

  useEffect(() => {
    if (isAvailablePlace) {
      if (pickedInst) {
        setPickedInst({
          ...pickedInst,
          isAvailable:
            isAvailablePlace.setAvailabilityOfInstitution.isAvailable,
        });
      }
      // window.location.reload();
    }
  }, [isAvailablePlace]);

  const sessionsState = () => {
    if (visData) {
      if (sessions.length !== 0) {
        for (const vis of visData.getVisitationsForAdminPanel.sessions) {
          const res = sessions.filter(
            (item) => item._id === vis._id && item.endTime !== null
          );
          if (res.length === 0) sessions.push(vis);
        }
        setSessions([...sessions]);
      } else setSessions([...visData.getVisitationsForAdminPanel.sessions]);
    }
  };

  const handleChange = (str: string) => {
    setSearchByName(str.toLowerCase());
  };

  return (
    <StyledAdmin
      ref={refToBottom}
      onScroll={() => onScroll(refToBottom, sessions, setPage)}
    >
      <Menu>
        <MenuComponent />
      </Menu>
      <Main>
        <Header
          className="session-header"
          style={{
            justifyContent: institutions.length > 1 ? "space-between" : "unset",
          }}
        >
          <h1>
            {t("session")} - {todaySession}
          </h1>
          {institutions.length > 1 && (
            <Button
              backgroundColor={"#FFFFFF"}
              color={"#FFFFFF"}
              onClick={() => setShownInst(!shownInst)}
              id="institutions"
              className={`${!shownInst ? null : "shown"}`}
            >
              <div className="everywhere-block">
                <span>{pickedInst ? pickedInst.name : t("everywhere")}</span>
                <img src={!shownInst ? forward_up : forward_down} alt="" />
              </div>
              <div
                ref={institutionRef}
                className={`${!shownInst ? "inactive" : "active"}`}
              >
                {institutions.map((institution) => (
                  <div
                    className="institution"
                    key={institution._id}
                    onClick={() => {
                      setName(institution._id);
                      setPickedInst(institution);
                    }}
                  >
                    <span>{institution.name}</span>
                  </div>
                ))}
                {pickedInst && (
                  <div
                    className="institution"
                    onClick={() => {
                      setName("all");
                      setPickedInst(null);
                    }}
                  >
                    <span>{t("everywhere")}</span>
                  </div>
                )}
              </div>
            </Button>
          )}
        </Header>
        <Container>
          <div className="statistics">
            <div className="stats-flex">
              <div className="stats-details">
                <div className="stats-icon">
                  <img src={bar} alt="" />
                </div>
                <div>
                  <h2>{visData?.getVisitationsForAdminPanel.numOfMoney} ₸</h2>
                  <p>{t("forToday")}</p>
                </div>
              </div>
              <div className="stats-details">
                <div className="stats-icon">
                  <img src={threePeople} alt="" />
                </div>
                <div>
                  <h2>{visData?.getVisitationsForAdminPanel.numOfSessions}</h2>
                  <p>{!isActive ? t("endedSessions") : t("activeSessions")}</p>
                </div>
              </div>
            </div>
            <div className="restrict-place">
              {pickedInst ? (
                <>
                  <div
                    className={
                      pickedInst.isAvailable ? `place-exists` : "no-place-green"
                    }
                    onClick={() =>
                      setAval({
                        variables: {
                          institutionId: pickedInst._id,
                        },
                      })
                    }
                  >
                    <p>{t("placesExist")}</p>
                  </div>
                  <div
                    className={
                      pickedInst.isAvailable ? `no-place` : "place-exists"
                    }
                    onClick={() =>
                      setAval({
                        variables: {
                          institutionId: pickedInst._id,
                        },
                      })
                    }
                  >
                    <p>{t("noPlace")}</p>
                  </div>
                </>
              ) : (
                <p className="choose-club-text">{t("pickClub")}</p>
              )}
            </div>
          </div>
          <div className="container">
            <div className="search-leeds">
              <div className="active-completed">
                <div
                  className={`active-block ${isActive ? "checked" : ""}`}
                  onClick={() => setActive(true)}
                >
                  <div className="active">
                    <span>{t("active")}</span>
                  </div>
                </div>
                <div
                  className={`completed-block ${!isActive ? "checked" : ""}`}
                  onClick={() => setActive(false)}
                >
                  <div className="completed">
                    <span>{t("completed")}</span>
                  </div>
                </div>
              </div>
              <div className="search">
                <img src={search} className="search-icon" alt="" />
                <Input
                  type="text"
                  placeholder={t("searchByName")}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
            </div>
            {visData &&
            visData.getVisitationsForAdminPanel.numOfSessions !== 0 ? (
              <DataItem
                getById={name}
                sessions={isActive ? activeSessions : sessions}
                isActive={isActive}
                date={todaySession}
                search={searchByName}
                numberOfSessions={
                  visData.getVisitationsForAdminPanel.numOfSessions
                }
                setPage={setPage}
              />
            ) : (
              <div className="inactive-sessions">
                <div className="inactive-header">
                  <p>{t("noSessions")}</p>
                </div>
                <img src={inactive} alt="" />
              </div>
            )}
          </div>
        </Container>
      </Main>
    </StyledAdmin>
  );
};

export default Admin;
