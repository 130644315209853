import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  ChangeEvent,
} from "react";
import {
  StyledLogin,
  StyledLoginContainer,
  SwitchUser,
  LoginBlock,
  Input,
  Form,
  InputFields,
  Button,
} from "../styled/Login";
import { useNavigate } from "react-router-dom";
import lock from "../assets/icons/lock.svg";
import mail from "../assets/icons/mail.svg";
import forwardUp from "../assets/icons/forward-up.svg";
import forwardDown from "../assets/icons/forward-down.svg";
import {
  UserParameters,
  UserFields,
  UserWorkerFields,
} from "../interfaces/User";
import { useLazyQuery } from "@apollo/client";
import { LOGIN, LOGIN_WORKER } from "../gql/query/loginToken";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../gql/localStorage/store";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const Login = (props) => {
  const { i18n, t } = useTranslation();
  const [isDropped, setDropped] = useState(true);
  const [adminPicked, setAdminPicked] = useState(true);
  const [user, setUser] = useState<UserParameters>({
    login: "",
    password: "",
  });

  const [validated, setValidated] = useState(false);

  const userRef = useRef<HTMLDivElement>(null);

  const [log, { data }] = useLazyQuery<UserFields>(LOGIN);

  const [logWorker, { data: dataWorker }] =
    useLazyQuery<UserWorkerFields>(LOGIN_WORKER);

  useEffect(() => {
    if (data) {
      saveToLocalStorage("JWT", data.loginAsOwner.token);
      saveToLocalStorage("cred", JSON.stringify(user));
      window.location.href = "/admin";
    }
  }, [data]);

  useEffect(() => {
    if (dataWorker) {
      saveToLocalStorage("WJWT", dataWorker.loginWorker.token);
      saveToLocalStorage("cred", JSON.stringify(user));
      window.location.href = "/admin";
    }
  }, [dataWorker]);

  useEffect(() => {
    if (user.login && user.password) {
      if (user.login.length >= 3 && user.password.length >= 3)
        setValidated(true);
      else setValidated(false);
    }
  }, [user]);

  const switchUser = (): void => {
    setAdminPicked(!adminPicked);
  };

  const handleChangeUser = () => {
    setDropped(!isDropped);
    showUser();
  };

  const showUser = (): void => {
    const ref = userRef.current;
    if (ref!.getAttribute("class")!.indexOf("inactive") === -1) {
      ref!.setAttribute("class", "inactive");
    } else if (ref!.getAttribute("class")!.indexOf("inactive") !== -1) {
      ref!.setAttribute("class", "active");
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  const submit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (adminPicked) {
      log({ variables: { ...user } });
    } else {
      logWorker({ variables: { ...user } });
    }
  };

  const [lang, setLang] = useState(() =>
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "ru"
  );
  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    console.log(lang_code);
    i18n.changeLanguage(lang_code);
    localStorage.setItem("lang", lang_code);
    setLang(lang_code);
  };

  useEffect(() => {
    if (!lang) return;
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <StyledLogin>
      <StyledLoginContainer>
        <SwitchUser onClick={handleChangeUser}>
          <div className="pick-user">
            {adminPicked ? (
              <span>{t("admin")}</span>
            ) : (
              <span>{t("secretary")}</span>
            )}
            <img src={isDropped ? forwardUp : forwardDown} alt="" />
          </div>
          <div ref={userRef} className="inactive" onClick={switchUser}>
            {!adminPicked ? (
              <span>{t("admin")}</span>
            ) : (
              <span>{t("secretary")}</span>
            )}
          </div>
        </SwitchUser>

        <Form onSubmit={submit} method="POST">
          <LoginBlock className="login-block">
            <div className="entry">
              <h1>{t("enter")}</h1>
              <p>{t("login")}</p>
            </div>
            <InputFields>
              <div className={`input-validate${data === null ? " wrong" : ""}`}>
                <img src={mail} className="mail" alt="" />
                <Input
                  icon={mail}
                  name="login"
                  type="email"
                  placeholder={t("email")}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className={`input-validate${data === null ? " wrong" : ""}`}>
                <img src={lock} className="lock" alt="" />
                <Input
                  icon={lock}
                  name="password"
                  type="password"
                  placeholder={t("password")}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {data === null && (
                <div className="validate-error">
                  <span>{t("error")}</span>
                </div>
              )}
            </InputFields>

            <div className="submit-buttons">
              <Button type="submit" active={validated}>
                {t("continue")}
              </Button>
              <div className="registration">
                <a
                  href="https://timeapp.kz/business/minutepay#rec386781553"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("register")}
                </a>
                {/* <Link to="">Забыли пароль?</Link> */}
              </div>
            </div>
          </LoginBlock>
        </Form>
        <select defaultValue={i18n.language} onChange={onChangeLang}>
          <option value={"ru"}>РУС</option>
          <option value={"en"}>ENG</option>
        </select>
      </StyledLoginContainer>
    </StyledLogin>
  );
};

export default Login;
