import styled from 'styled-components';



export const Loaded = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
`;


export const DarkBackground = styled("div")<{disappear: boolean}>`
    /* display: none; Hidden by default */
    /* position: fixed; Stay in place */
    /* z-index: 999; Sit on top */
    /* left: 0; */
    /* top: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); 
    ${props =>
        props.disappear &&
        css`
        display: block; /* show */
    `}
`



export const StyledClient = styled.div`

    display: flex;
    height: 100%;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;


    .data-header {
        display: flex;
        justify-content: center;
        gap: 15px;
    }


    .go-back {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 0 15px 0;
    }


    .active-session, .story-visit {
        h2 {
            font-size: 34px;
        }
    }

    .story-visit{
        display: flex;
        justify-content: space-between;

    }
    .story-visit-block {
        display: flex;

        justify-content: space-between;

        h2 {
            font-size: 34px;
        }
        .sort-by {
            display: flex;
            align-items: center;
            gap: 21px;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 295px;
                min-height: 61px;
                background: #FFFFFF;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
            }

            div.insts {
                min-height: 40px !important;
            }

            .margin-top {
                margin-top: 5px;
            }
            .everywhere, .sort-type {
                padding: 6px 5px;
            }
            .everywhere, .sort-type {
                min-width: 295px;
                .by-date, .everywhere-block {
                    /* width: 100%; */
                }
                .everywhere-block {
                    min-height: 20px;
                }
            }
            .active {
                width: 100%;
                .by-price, .by-time, .by-date {
                    box-shadow: none;
                    gap: 9px;
                }
            }

            .by-date, .by-price, .by-time {
                gap: 9px;
                box-shadow: none;
            }
            .sort-type {
                flex-direction: column;
                cursor: pointer;
                .inactive {
                    display: none;
                }
    
                .active {
                    display: block;
                }
            }
            .everywhere {
                flex-direction: column;

                &-block {
                    gap: 9px;
                    cursor: pointer;
                    box-shadow: none;
                }

                .inst-element {
                    box-shadow: none;
                }
                .inactive {
                    display: none;
                }
                .active {
                    display: block;
                }
            }
        }
    }


    .active-sessions {
        background: #F8F8F8;
        border-radius: 15px;

        h2 {
            padding: 30px 0 0 30px;
            font-size: 38px;
        }
        .session-scroll { 
            overflow-y: scroll;

            .story-session {
                margin: 15px 15px;
                gap: 20px;
                background: #FFFFFF;
                border-radius: 15px;
                align-items: center;
            }

            .stats-number {
                .start-time, .duration, .price {
                    border: none;
                }
            }

            button {
                max-width: 152px;
                max-height: 60px;
                border-radius: 6px;
            }
        }
    }
    .active-sessions {
        overflow-y: unset !important;
    }
    .session-scroll {
        max-height: 250px;
    }

    .completed-sessions {
        max-height: 400px;
    }

    .story-completed {
        .story-visit-block {
            padding-top: 20px;
        }
    }

    .active-sessions {
        .start-time, .duration, .price {
            border: none !important;
        }
        .stats-number {
            .stats-number-block {
                max-width: 100% !important;
            }
        }

    }
    .completed-sessions, .active-sessions {
        margin-top: 20px;
        overflow-y: scroll;
        .story-session {
            display: flex;
            justify-content: space-between;
            background: #F8F8F8;
            border-radius: 15px;
            padding: 20px;
            margin-bottom: 20px;
            gap: 15px;

            &-id {
                display: flex;
                max-width: 570px;
                width: 100%;
                justify-content: space-between;
                gap: 25px;
                .session-name-address {
                    p {
                        margin-bottom: 0;
                        color: #979797;
                    }
                    .date-session {
                        margin-top: 0;
                    }
                    h3 {
                        margin-top: 0;
                    }

                }
                .short-id {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                }
                .service-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .stats-number {

                max-width: 600px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                gap: 20px;


                &-block {
                    max-width: 325px;
                    width: 100%;
                    display: flex;
                    gap: 20px;
                    justify-content: center;
                }
                .start-time, .duration, .price {
                    max-width: 163px;
                    width: 100%;
                    max-height: 60px;
                    background: #F8F8F8;
                    border: 0.5px solid #979797;
                    box-sizing: border-box;
                    border-radius: 6px;
                    text-align: center;

                    /* .block-info {
                        padding-left: 10px; 
                    } */
                    p.paragraph {
                        font-size: 14px;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                    p.time {
                        font-size: 18px;
                        margin: 0;
                        color: #434343;
                    }
                }
            }
        }

    }


    @media (max-width: 1200px) {

        justify-content: center;
        
        .story-session-id {
            max-width: 380px !important;
        }

        .sort-by {
            .everywhere, .everywhere-block {
                // min-width: 139px !important;
                max-width: 140px;

            }

            .sort-type, .by-date, .by-price, .by-time {
                min-width: 212px !important;
                max-width: 215px;
            }
        }

        p.paragraph {
            margin-top: 0;
            font-size: 14px !important;
        }
        p.time {
            font-size: 18px !important;
        }

        
    }


    @media (max-width: 960px){
        padding: 20px;
        overflow-y: scroll;

        .data-header {
            flex-direction: column;
        }
        
        .story-visit-block h2 {
            font-size: 24px;
        }
        .completed-sessions, .active-sessions {

            max-height: unset;
            .story-session {
                flex-direction: column;
                .story-session-id, .stats-number {
                    max-width: 100% !important;
                }
                .stats-number {
                    .price {
                        max-width: 33%;
                    }

                    .stats-number-block {
                        max-width: 67%;
                        .start-time, .duration {
                            max-width: 50%;
                        }
                    }
                }
                .session-name-address {
                    display: flex;
                    flex-direction: column-reverse;

                    h3 {
                        margin-bottom: 0;
                    }

                    p {
                        margin-top: 0;
                    }
                }
                .stats-number {
                    .start-time, .duration, .price {
                        .block-info {
                            padding-bottom: 10px;
                            .paragraph {
                                font-size: 14px !important;
                            }
                            .time {
                                font-size: 18px !important;
                            }
                        }
                    }
                }
            }

            /* .session-scroll {
                max-height: 300px;
            } */
        }
        .active-sessions{

            button {
                max-width: 100% !important;
                max-height: 50px;
            }

            .story-session {
                flex-direction: column;

                .story-session-id, .stats-number {
                    max-width: 100% !important;
                    justify-content: space-between !important;
                }
            }
        }

    }


    @media (max-width: 640px) {


        .stats-number {

            flex-direction: column;

            .price {
                max-width: 100% !important;
                justify-content: center;
                .block-info {
                    text-align: center;
                }
            }

            .start-time, .duration {
                max-width: 50% !important;
                text-align: center;
            }

            &-block {

                max-width: 100% !important;

            }
        }

        .by-category {
            width: 100% !important;

            .by-price, .by-time {
                width: 100% !important;
            }
        }
        .everywhere {

            .inst-element {
                box-shadow: none;
                width: 100% !important;
                div {
                    width: 100% !important;
                }
            }
        }
        .story-visit-block {

            flex-direction: column;

            .sort-by {

                
                div {
                    width: 50%;
                    min-height: 45px !important;
                }
                .everywhere-block, .by-date {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .story-visit-block {
            .sort-by {
                flex-direction: column;
                .everywhere, .sort-type {
                    max-width: 100%;
                    width: 100%;
                    div {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .stats-number {
            .block-info {
                padding: 0 !important;
            }
            p.time {
                margin-bottom: 10px !important;
            }
        }

    }
`;



export const Main = styled.div`

    width: 100%;
`;


export const ClientData = styled.div`

    max-width: 45%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;


    .call-mail-block {

        display: flex;
        justify-content: center;
        padding: 30px;
        gap: 10px;

        .call-mail {
            max-width: 218px;
            width: 100%;
            min-height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #007AFF;
            background: #F8F8F8;
            border-radius: 6px;
            gap: 10px;

            span a {
                text-decoration: none;
            }
            svg { 
                color: #007AFF;
            }

            &.nomail {
                color: #434343;
                svg {
                    color: #434343;
                }
            }
        }
    }
    .client-data {
        padding: 42px 0 20px;
        .client {
            display: flex;
            flex-direction: column;
            padding-top: 10px;

            p.fullname {
                font-weight: bold;
                font-size: 24px;
            }
            p {
                font-size: 16px;
                margin: 0;
                text-align: center;
            }
        }
        &-capital {
            display: flex;
            justify-content: center;
        }
    }


    @media (max-width: 1200px) {

        .call-mail-block {

            .call-mail {
                max-width: 180px !important;
            }
        }

        .client-data {

            display: flex;
            padding-left: 30px;

            .client-data-capital {

                div {
                    min-width: 86px;
                    min-height: 86px !important;
                }
                span {
                    font-size: 51px;
                }
            }

            .client {
                padding-top: 0;
                align-items: flex-start;
                justify-content: center;
            }
        }
    }


    @media (max-width: 960px) {

        max-width: 100%;


        .client-data {
            flex-direction: column;
            padding-left: 0;
            .client {
                align-items: center;
            }

        }
        .call-mail-block {

            padding-top: 30px;
        }

    }


    @media (max-width: 480px) {

        width: unset;

        .call-mail-block {
            flex-direction: column;
            padding: 30px 15px 30px !important;
            .call-mail {
                max-width: 100% !important;
            }
        }

    }
`;


export const Statistics = styled.div`

    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 55%;
    width: 100%;
    .average-price-time {
        display: flex;
        align-items: center;
        gap: 10px;

        .average-price, .average-time {
            width: 50%;
            display: flex;
            align-items: center;
            background: #F8F8F8;
            border-radius: 15px;
            padding: 15px;
            gap: 20px;
        }
    }

    .overall-sum {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px;
        background: #F8F8F8;
        border-radius: 15px;
        
    }

    .price-visit, .time-visit, .overall-sum {
        h2 {
            margin-bottom: 0;
        }
        p {
            margin-top: 0;
            color: #979797;
            max-height: 37px;
            overflow: hidden;
        }
    }

    @media (max-width: 1200px) {

        .average-price, .average-time, .overall-sum {

            max-height: 70px;

            .stats-icon {
                img {
                    max-width: 40px;
                }
            }
        }

    }

    
    @media (max-width: 960px) {
        max-width: 100%;

        .average-price, .average-time {
            width: 50%;
        }
    }

    @media (max-width: 480px) {
        .average-price-time {
            .average-price, .average-time {
                padding: 10px;
                gap: 5px;
            }
        }
    }

`;