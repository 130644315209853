import React from 'react';
import {Visitations} from '../interfaces/Visitations';
import { HistoryVisit } from "../interfaces/Client";
import {HistoryVisitations} from '../interfaces/Statistics';
import {Comment} from '../pages/InstitutionInfo';

export const onScroll = (ref: React.RefObject<HTMLDivElement>, data: Visitations[] | HistoryVisit[] | HistoryVisitations[] | Comment[], setPage: (value: React.SetStateAction<number>) => void) => {
    if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            if (data !== undefined && data.length !== 0 && data.length % 10 === 0) {
                setPage((prev) => {
                    return prev + 1;
                });
            }
        }
    }
};