export const getTimeDifference = (date2: string, date1: string) => {

    const dateFirst = new Date(date1);
    const dateSecond = new Date(date2);

    const timeSpent = Math.floor((Math.abs(dateSecond.valueOf() - dateFirst.valueOf())));

    const timeSpentSeconds = timeSpent/1000;

    const minutes = Math.floor(timeSpentSeconds/60).valueOf();
    const seconds = Math.floor(timeSpentSeconds%60);

    return [`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, minutes];
}


export const getTimeText = (minutes: number, seconds: number, hours: number) => {

    // const dateFirst = new Date(date1);
    // const dateSecond = new Date(date2);

    // const timeSpent = Math.floor((Math.abs(dateSecond.valueOf() - dateFirst.valueOf())));

    // const timeSpentSeconds = timeSpent/1000;

    // const minutes = Math.floor(timeSpentSeconds/60).valueOf();
    // const seconds = Math.floor(timeSpentSeconds%60);

    return [`${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`, minutes];
}

