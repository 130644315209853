import {gql} from '@apollo/client';

export const GET_INST_OWNER = gql`

    query {
        getInstitutionsOfOwner {
            _id,
            avatarURL {
                M,
                XL,
                thumbnail
            },
            averagePrice,
            city,
            contacts {
                email,
                instagram,
                phoneNumber,
                whatsApp
            },
            description,
            location {
                address
            },
            isAvailable,
            name,
            numOfWorkers,
            rating,
            ratingCount,
            videoURL,
            workers {
                _id,
                fullName,
                login,
                institution {
                    _id,
                    name
                }
            },
            workTimes {
                _id,
                startTime,
                endTime
            }, 
            type,
            galleryURLs {
                M,
                XL,
                thumbnail
            }
        }
    }

`;