import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InstituitionObject } from "../interfaces/Instituion";
import {
  StyledInstitutionInfo,
  Header,
  InstContainer,
  Main,
  Input,
} from "../styled/Institution";
import { NameCapital } from "../styled/Admin";
import MenuComponent from "../component/MenuComponent";
import { Menu } from "../styled/Admin";
import { useQuery } from "@apollo/client";
import { GET_COMMENTS } from "../gql/query/getComments";
import two_people from "../assets/icons/two-people.svg";
import export_icon from "../assets/icons/export.svg";
import star from "../assets/icons/star.svg";
import count from "../assets/icons/rating-count.svg";
import edit from "../assets/icons/edit.svg";
import desc from "../assets/icons/desc.svg";
import address from "../assets/icons/address.svg";
import call from "../assets/icons/call.svg";
import instagram from "../assets/icons/instagram.svg";
import telegram from "../assets/icons/telegram.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import { Carousel } from "react-carousel-minimal";
import ImageSlider from "../component/ImageSlider";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { onScroll } from "../utils/onScroll";
import { t } from "i18next";

// import 'antd/dist/antd.css';

export interface Gallery {
  M: string;
  XL: string;
  thumbnail: string;
}

export interface CommentData {
  getCommentsOfAInstitution: Comment[];
}

export interface Comment {
  _id: string;
  client: {
    _id: string;
    fullName: string;
    photoURL: {
      M: string;
      XL: string;
      thumbnail: string;
    } | null;
  };
  dateAdded: string;
  rating: number;
  text: string;
}

const InstitutionInfo = () => {
  const location = useLocation();
  const state = location.state as InstituitionObject;

  const [galleryImages, setGalleryImages] = useState<Gallery[]>([]);

  const [description, setDescription] = useState("");
  const [address, setAddress] = useState(state.location.address);

  const [page, setPage] = useState(1);
  const commentRef = useRef<HTMLDivElement>(null);

  const [comments, setComments] = useState<Comment[]>([]);
  const { data: commentData } = useQuery<CommentData>(GET_COMMENTS, {
    variables: {
      institutionId: state._id,
      page: page,
    },
  });

  useEffect(() => {
    setGalleryImages([...state.galleryURLs]);
  }, [state]);

  useEffect(() => {
    getComments();
  }, [commentData]);

  const getComments = () => {
    setComments([
      ...commentData.getCommentsOfAInstitution.filter(
        (item) => item.text !== ""
      ),
    ]);
  };

  return (
    <StyledInstitutionInfo
      onScroll={() =>
        onScroll(commentRef, commentData.getCommentsOfAInstitution, setPage)
      }
    >
      <Menu>
        <MenuComponent />
      </Menu>
      <Main>
        <Header>
          <h1>{state.name}</h1>
          <div className="header-icons">
            <div className="two-people">
              <img src={two_people} alt="" />
            </div>
            <div className="export">
              <img src={export_icon} alt="" />
            </div>
          </div>
        </Header>
        <InstContainer>
          <div className="image-slider">
            <ImageSlider images={state.galleryURLs} />
          </div>

          <div className="price-rating">
            <div className="average-price">
              <span>
                {state.averagePrice}₸/{t("min")}
              </span>
              <img src={edit} alt="" />
            </div>
            <div className="rating">
              <img src={star} alt="" />
              <span>{state.rating}</span>
            </div>
            <div className="rating-count">
              <img src={count} alt="" />
              <span>{state.ratingCount}</span>
            </div>
          </div>

          <div className="description">
            <h1>Описание</h1>
            <div>
              <textarea
                className={`edit-text ${
                  description.length > 0 ? "typing" : ""
                }`}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>

              <img src={desc} alt="" />
            </div>
          </div>

          <div className="address">
            <div className="address-info">
              <h1>Адрес</h1>
              <span>{address.split(",")[0]}</span>
              {/* <Input/> */}
              <div className="address-change">Изменить адрес</div>
            </div>
            <img src={address} alt="" />
          </div>

          <div className="contacts">
            <h1>Контакты</h1>
            <div className="contact-blocks">
              <div className="contact-block">
                <img src={call} alt="" />
                <h2>Основной номер телефона</h2>
                <div className="input">
                  <label>
                    Номер телефона
                    <Input type="text" value={state.contacts.phoneNumber} />
                  </label>
                  {/* <CFormInput
                                        type="email"
                                        id="floatingInputInvalid"
                                        floatingLabel="Номер телефона"
                                        placeholder="+7 777 777 77 77"
                                        defaultValue="test@example.com"
                                        valid
                                    /> */}
                  {/* <Button></Button> */}
                </div>
              </div>
              <div className="contact-block">
                <img src={instagram} alt="" />
                <h2>Профиль Instagram</h2>
                <div className="input">
                  <label>
                    Номер телефона
                    <Input type="text" value={state.contacts.instagram} />
                  </label>
                  {/* <Button></Button> */}
                </div>
              </div>

              <div className="contact-block">
                <img src={whatsapp} alt="" />
                <h2>Номер Whatsapp</h2>
                <div className="input">
                  <label>
                    Номер WhatsApp
                    <Input type="text" value={state.contacts.whatsApp} />
                  </label>
                </div>
              </div>

              {/* <div className="contact-block">
                                <img src={telegram} alt=""/>
                                <div className="input">
                                    <label>
                                        Профиль Telegram
                                        <Input type="text" value={state.contacts.}/>
                                    </label>
                                </div>
                            </div> */}
            </div>
          </div>

          <div className="additional-block">
            <h1>Дополнительно</h1>
            <div className="additional-info">
              <div className="switch">
                <p>Оплата картой</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Wi-Fi</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Платная парковка</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Бесплатная парковка</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Бассейн</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Русская баня</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
              <div className="switch">
                <p>Хамам</p>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // defaultChecked
                />
              </div>
            </div>
          </div>

          <div className="rating-review">
            <h1>Рейтинг и отзывы</h1>

            <div className="reviews">
              {comments.map((item) => {
                return (
                  <div className="review" key={item._id}>
                    <div className="image-fullName">
                      {item.client.photoURL !== null ? (
                        <div className="image">
                          <img src={item.client.photoURL.M} alt="" />
                        </div>
                      ) : (
                        <NameCapital
                          active={false}
                          minHeight={70}
                          minWidth={70}
                        >
                          {item.client.fullName[0].toUpperCase()}
                        </NameCapital>
                      )}
                      <h2 className="client">{item.client.fullName}</h2>
                    </div>
                    <div className="comment">
                      <p>{item.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </InstContainer>
      </Main>
    </StyledInstitutionInfo>
  );
};

export default InstitutionInfo;
