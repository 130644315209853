import styled from 'styled-components';



export const StyledLogin = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;


`;


export const StyledLoginContainer = styled.div`
    max-width: 389px;
    width: 100%;
    max-height: 490px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 480px) {
        max-width: 280px;
    }
`;


export const SwitchUser = styled.div`
    
    max-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    flex-direction: column;
    cursor: pointer;

    .inactive{
        display: none;
    }

    .active{
        display: block;
        color: #007AFF;
    }
    .pick-user{
        display: flex;
        gap: 10px;
    }
`;

export const LoginBlock = styled.div`
    background: #FFFFFF;

    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;

    div.entry{
        padding: 30px 30px 0;
        h1{
            margin: 0;
            font-weight: bold;
        }
        p{
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #B7B7B7;
        }
    }


    .submit-buttons{
        padding: 0 30px 0 !important;

        div.registration{
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 0 40px;
            a {
                text-decoration: none;
                color: #007AFF;
            }


        }
    }

    div{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px 30px 42px;
    }

    @media (max-width: 480px) {
        
        .registration{
            flex-direction: column-reverse !important; 
            justify-content: center;
            text-align: center;
            padding: 12px 0 25px !important;
        }
    }
`;

type IconProps = {
    icon: string
}

export const Input = styled.input<IconProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    background: #F7F7F7;
    border-radius: 15px;
    height: 45px;
    max-width: 260px;

    &::before{
        content: '';
        position: absolute;
        background: url(${(props) => (props.icon)}) no-repeat;
        background-size: cover;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 25px;
        height: 25px;
    }

    border: none;


    &:focus{
        outline: none;
    }
    &::placeholder{
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }

    @media (max-width: 480px) {
        padding: 10px 6px;
    }
    
`;

export const InputFields = styled.div`
    position: relative;
    img.mail{
        width: 20px;
        height: 20px;
        line-height: 26px;
        text-align: center;
        margin-right: -26px;
        position: absolute;
        top: 51px;
        left: 49px;
        z-index: 1;
        float: left;
    }

    img.lock{
        width: 20px;
        height: 20px;
        line-height: 26px;
        text-align: center;
        margin-right: -26px;
        position: absolute;
        top: 125px;
        left: 49px;
        z-index: 1;
        float: left;
    }

    img + input{
        padding-left: 50px;
    }

    div{
        padding: 0;
    }

    .input-validate { 
        &.wrong {
            background: #FFE6E6;
            border: 0.5px solid #F40F0F;
            border-radius: 15px; 
            /* color: #FFE6E6; */
            input {
                background: #FFE6E6;
            }
        }
    }

    .validate-error {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #F40F0F;
    }
`;
export const Form = styled('form')<{maxWidth?: number, width?: number}>`

    ${props => props.maxWidth !== undefined ? 'max-width: ' + props.maxWidth + 'px' : ''};
    ${props => props.width !== undefined ? 'width: ' + props.width + '%' : ''};
`;


export const Button = styled('button')<{active?: boolean}>`
    max-height: 60px;
    height: 100%;
    width: 100%;
    background: ${props => props.active === true ? '#007AFF' : '#F0F0F0'};
    border-radius: 15px;
    color: #FCFCFC;
    box-shadow: none;
    border: none;
    height: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    &:hover{
        cursor: pointer;
    }
`;