import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Client from "./pages/Client";
import LogIn from "./pages/Login";
import Statistics from "./pages/Statistics";
import ProtectedRoute from "./pages/ProtectedRoute";
import Institution from "./pages/Institution";
import InstitutionInfo from "./pages/InstitutionInfo";
import { getFromLocalStorage } from "./gql/localStorage/store";
import Settings from "./pages/Settings";
import CreateInst from "./pages/CreateInst";
import "./App.css";
import Admin from "./pages/Admin";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          getFromLocalStorage("JWT") || getFromLocalStorage("WJWT") ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/login" element={<LogIn />} />
      <Route
        path="/admin"
        element={
          getFromLocalStorage("JWT") || getFromLocalStorage("WJWT") ? (
            <Admin />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/admin/:_id"
        element={
          getFromLocalStorage("JWT") ? <Client /> : <Navigate to="/admin" />
        }
      />
      <Route
        path="/admin/stats"
        element={
          getFromLocalStorage("JWT") ? <Statistics /> : <Navigate to="/admin" />
        }
      />
      <Route
        path="/admin/object-info"
        element={
          getFromLocalStorage("JWT") ? <Statistics /> : <Navigate to="/admin" />
        }
      />
      <Route
        path="/admin/object-info/:_id"
        element={
          getFromLocalStorage("JWT") ? (
            <InstitutionInfo />
          ) : (
            <Navigate to="/admin" />
          )
        }
      />
      <Route
        path="/admin/object-info/create"
        element={
          getFromLocalStorage("JWT") ? <CreateInst /> : <Navigate to="/admin" />
        }
      />
      <Route
        path="/admin/settings"
        element={
          getFromLocalStorage("JWT") || getFromLocalStorage("WJWT") ? (
            <Settings />
          ) : (
            <Navigate to="/admin" />
          )
        }
      />
    </Routes>
  );
}

export default App;
