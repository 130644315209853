import {gql} from '@apollo/client';

export const GET_WORKER = gql`

    query{
        getWorker{
            _id,
            fullName,
            institution {
                _id,
                name
            },
            login,
            token
        }
    }

`;