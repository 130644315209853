import {gql} from '@apollo/client';


export const LOGIN = gql`
    query(
        $login: String!
        $password: String!
    )
    {
        loginAsOwner(
            login: $login
            password: $password
        )
        {
            _id
            token
            email
            phoneNumber
            fullName
        }
    }
`;



export const LOGIN_WORKER = gql`

    query(
            $login: String!
            $password: String!
        )
        {
            loginWorker(
                login: $login
                password: $password
            )
            {
                _id,
                token,
                fullName,
                institution {
                    _id,
                    name
                },
                login
            }
        }
`;