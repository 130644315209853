import styled from 'styled-components';


export const StyledStats = styled.div`

    height: 100%;
    padding: 40px;
    margin: auto;
    overflow-y: scroll;
    display: flex;
    box-sizing: border-box;

    .menu-bar {
        position: sticky;
        top: 0;
    }

    .show-instituions {
        min-width: 295px;
        min-height: 52px;
        gap: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        padding: 6px 5px;
        cursor: pointer;

        &.other {
            min-height: 35px;
            max-height: 40px;
        }
        .everywhere {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.pick-block {
            cursor: pointer;
            display: none !important;
            &.active {
                display: flex !important;
                justify-content: center;
                align-items: center;
                max-width: 92px;
                width: 100%;
                color: #007AFF;
                max-height: 42px;
                min-height: 40px;
                color: #434343;
                background: #FFFFFF;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                border-radius: 6px;
            }
        }
    }

    .data-scroll {
        overflow-y: scroll;

        .personal-data, .instituion-name {
            gap: 150px;
        }
    }

    
    .instituions {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .show-instituions {
            height: 100%;
        }
    }

    @media (max-width: 1200px) {
        justify-content: center;
        .menu-bar{
            position: fixed;
            top: unset;
        }
    }



`;


export const Header = styled.div`

    display: flex;
    justify-content: space-between;

    .instituion-separate {
        display: flex;
        align-items: center;
        gap: 20px;


        .separate-by {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 350px;
            min-height: 52px;
            /* max-height: 55px; */
            padding: 6px 5px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
            border-radius: 15px; 

            .margin-top {
                margin-top: 10px;
            }
            .width-100{
                width: 100%;
            }
            .flex {
                display: flex;

            }
            .j-center {
                justify-content: center;
            }
            .a-center{
                align-items: center;

            }
            .pick-block {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                min-width: 130px;
                min-height: 50px;
                width: 100%;
                color: #007AFF;
                cursor: pointer;
                
            }
            .pick-own-date {
                position: relative;
            }
            .own-date {
                position: absolute;
                top: 200%;
            }

            .active {
                max-height: 42px;
                min-height: 40px;
                color: #434343;
                background: #FFFFFF;
                cursor: unset;
            }
        }
        .default {
            display: flex;
        }
        .responsive {
            display: none;
        }
    }

    @media (max-width: 1200px){
        .responsive {
            display: flex !important;
            flex-direction: column;
            min-height: 50px;
            position: relative;
            .active {
                max-width: 100% !important;
                width: 100% !important;
            }

            .absolute-options{
                width: 100%;
                position: absolute;
                top: 99%;
            }
            .active-choose {
                width: 100%;
                max-width: 100% !important;
                /* min-height: 52px; */
                color: #000 !important;
                background: #FFFFFF ;
                box-shadow: 2px 2px 5px rgb(0 0 0 / 15%) ;
                border-radius: 15px;
                gap: 10px;

            }
        }
        .instituion-separate {
        
            .separate-by.default {
                display: none !important;
            }
        }
    }

    @media (max-width: 960px) {
        .instituions, .separate-by, .show-instituions {
            max-width: 143px !important;
            min-width: 142px !important;
        }
    }
    @media (max-width: 480px) {
        flex-direction: column;

        .instituion-separate {
            .instituions, .responsive {
                width: 50% !important;   
                max-width: 50% !important;
                min-width: unset !important;
                .show-instituions {
                    max-width: 100% !important;
                    min-width: unset !important;
                    width: 100%;
                }
                .active {
                    max-width: 100% !important;
                    width: 100% !important;
                }
            }


        }
    }

`;



export const Statistic = styled.div`

    margin-top: 60px;
    .stats-block {

        display: flex;
        max-width: 295px;
        min-height: 120px;
        width: 100%;
        gap: 20px;
        background: #F9F9F9;
        border-radius: 15px;
        .stats-icon {
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
        .session-num, .profit-num {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            h2 {
                margin-top: 0;
            }
            p {
                color: #979797;
                margin-bottom: 0;
            }
        }
    }
    .main-stats {
        display: flex;
        gap: 10px;
        margin-top: 35px;
        .stats-block {
            width: 100%;
            max-width: 50%;
        }
    }

    .barChart > div > div {
        position: static !important;
    }

    @media (max-width: 480px) {

        .main-stats {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .stats-block {
                max-width: 100%;
            }
        }
    }
`;



export const HistoryVisit = styled.div`

    margin-top: 50px;
    .history-visit {
        display: flex;
        justify-content: space-between;
        .inst-search {
            display: flex;
            gap: 20px;
            align-items: center;

            .show-instituions {
                height: 100%;
                padding: 10px 8px;

                .margin-top {
                    margin-top: 10px;
                }
            }

            .search-by-name {
                display: flex;
                align-items: center;
                max-width: 350px;
                width: 100%;
                gap: 10px;
                background: #F8F8F8;
                border-radius: 15px;
                padding: 20px;
            }
        }
    }

    @media (max-width: 960px) {
        .history-visit {
            flex-direction: column;

            .instituions{
                width: 50% !important;
                min-width: 50% !important;
                .show-instituions {
                    min-width: 100% !important;
                }
            }
            .search-by-name {
                width: 50% !important;
                margin: 0 !important;
            }
        }
    }

    @media (max-width: 480px) {

        .history-visit {
            .instituions{
                width: 100% !important;
                .show-instituions {
                    min-width: 100% !important;
                }
            }
            .search-by-name {
                width: 100% !important;
                box-sizing: border-box;
                max-width: unset !important;
            }
        }


        .inst-search {
            flex-direction: column;
        }
    }
`;



export const Input = styled.input`

    width: 100%;
    font-size: 18px;
    background: #F8F8F8;
    border-radius: 15px;
    border: none;

    &:focus {
        outline: none;
    }

`;