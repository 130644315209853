import {gql} from '@apollo/client';

export const VIEW_VISITATIONS = gql`
    subscription ($institutionId: String){
        viewVisitations(institutionId: $institutionId){
            _id,
            client {
                _id,
                fullName,
                phoneNumber,
                dateOfBirth,
                email,
                sex,
                city
            },
            startTime,
            endTime,
            institution {
                _id,
                name
            },
            service {
                price,
                bufferTimeMinutes
            },
            status,
            price
        }
    } 

`;