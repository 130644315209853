import styled from 'styled-components';


export const StyledInstitution = styled.div`

    display: flex;
    overflow-y: scroll;

    .menu-bar {
        position: sticky;
        top: 0;
    }

    @media (max-width: 960px)
    {
        justify-content: center;
        .menu-bar {
            position: fixed;
            top: unset;
        }
    }
`;



export const Container = styled.div`

    width: 100%;

    .header-object {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 36px;
    }

    .inst-data {
        display: flex;
        gap: 19px;
        max-width: 100%;
        flex-wrap: wrap;
    }

    .workers {
        &-container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }
`;


export const Object = styled.div`

    max-width: 558px;
    padding: 20px;

    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;


    .inst-image img {
        width: 100%;
        border-radius: 16px;
    }

    .inst-info {


        .price-rating {

            display: flex;
            width: 100%;
            gap: 10px;

            .average-price, .rating, .rating-count {
                width: 33%;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .average-price {
                color: #FFFFFF;
                background: #434343;
                border-radius: 6px;
            }

            .rating, .rating-count {
                background: #F9F9F9;
                border-radius: 6px;
            }
        }

        .inst-worktimes {
            .location-address {
                margin-bottom: 0;
                color: #979797;
            }
            .worker-count {
                margin-top: 0;
                color: #979797;
            }
        }
    }

`



export const Button = styled('button')<{active?: boolean, maxWidth?: number}>`

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: ${props => props.maxWidth !== undefined ? `${props.maxWidth}px` : '174px'};
    width: 100%;
    min-height: 60px;
    font-size: 16px;
    color: #FCFCFC;
    border: none;
    background: #007AFF;
    border-radius: 15px;
`;



export const Worker = styled.div`

    max-width: 270px;
    width: 100%;
    min-height: 135px;
    max-height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;

    .fullname {
        color: #434343;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
    }
    .inst-name {
        color: #979797;
        font-size: 16px;
        margin-top: 0;
    }
`;



export const AddWorker = styled('div')<{active: boolean}>`

    display: ${props => props.active ? 'block' : 'none'};
    max-width: 325px;
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px;

    .new-worker-header {
        display: flex;
        justify-content: space-between;
        h3 {
            font-size: 24px;
        }
    }

    .input-block {
        max-width: 325px;
        width: 100%;
        min-height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        background: #F7F7F7;
        border-radius: 15px;

        input {
            padding: 20px;
        }
    }


    .work-place {
        min-height: 60px;
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
    }
    button {
        margin-top: 20px;
    }
`;


export const Main = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;

`;

export const StyledInstitutionInfo = styled.div`


    display: flex;
    
    /* width: 100%; */
    /* max-width: 1140px; */

    padding: 42px;

    h1 { 
        font-size: 42px;
    }

`;

export const Header = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;


    .header-icons {

        display: flex;
        gap: 20px;

        .two-people, .export {

            display: flex;
            align-items: center;
            justify-content: center;

            width: 64px;
            height: 64px;

            background: #FFFFFF;
            box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
            border-radius: 100px;
        }
    }

`;



export const InstContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    
    .image-slider {

        .image-pagination {
            position: relative;

            display: flex;
            align-items: center;
            .pagination-buttons {
                position: absolute;

                width: 100%;
                display: flex;
                justify-content: space-between;
                button {
                    background: none;
                    border: none;
                }
            }

            .main-image {
                max-width: 1000px;
                max-height: 586px;
            }
        }
    }


    .price-rating {
        display: flex;
        gap: 22px;

        .average-price, .rating, .rating-count {
            width: 33%;
            min-height: 87px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            font-size: 38px;
        }

        .average-price {
            background: #FFFFFF;
            box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
            border-radius: 15px;
        }

        .rating, .rating-count {
            background: #F9F9F9;
            border-radius: 15px;
            img {
                width: 35px;
                height: 35px;
            }
        }
    }

    .description {
        div {
            display: flex;
            align-items: center;
            gap: 70px;
            .edit-text {
                /* max-width: 878px; */
                width: 100%;
                height: 145px;
                padding: 20px;

                font-family: 'SF Pro Display Regular';
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #434343;
                
                border-radius: 15px;
                box-sizing: border-box;

                &:focus {
                    outline: none;
                }

                &.typing {
                    border: 0.5px solid #0F84F4;
                }
            }
        }
    }

    .address {
        display: flex;
        width: 100%;

        .address-info {
            width: 100%;

            span { 
                font-size: 28px;
                color: #979797;
            }
            h1 {
                margin-bottom: 5px;
            }

            .address-change {
                color: #007AFF;
                font-size: 28px;
                margin-top: 20px;
            }
        }
    }
    .contacts {

        .contact-blocks {

            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            .contact-block {
                max-width: 43%;
                width: 100%;
                padding: 25px;
                
                background: #FFFFFF;
                border-radius: 15px;
                box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
            
                .input {
                    label {
                        display: flex;
                        flex-direction: column;

                    }
                }
            }
        }
    }

    .additional-block {

        padding: 35px;
        box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
        border-radius: 15px;
        .additional-info {

            .switch {

                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    font-size: 24px;
                }

                button {
                    /* min-height: 25px; */
                    /* min-width: 0px; */
                }
            }
        }
    }

    .rating-review {
        max-width: 100%;
        width: 100%;



        .reviews {

            display: flex;
            flex-direction: column;
            gap: 10px;

            .review {
                
                background: #F8F8F8;
                border-radius: 15px;
                padding: 30px;

                .image-fullName {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    color: #434343;
                    .image {
                        display: inline-block;
                        border-radius: 50%;

                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                        }
                    }

                    .client {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 34px;
                        margin: 0;
                    }

                    div {
                        margin: 0;
                    }
                }

                .comment {
                    margin: 20px 0 0 0;
                    p {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;
                    }
                }
            }
        }
    }
`;



export const Description = styled.div`



`;

export const Input = styled('input')`

    max-width: 404px;
    max-height: 65px;

`;