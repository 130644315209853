import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { getTimeText } from '../utils/timeDifference';

const Timer = ({ startDate }) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);

  const getTime = useCallback((startDate) => {
    const time =  new Date(new Date().toISOString()).getTime() - Date.parse(startDate);
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setHours(Math.floor((time / 1000 / 60 / 60) % 60));
    setSeconds(Math.floor((time / 1000)) % 60);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getTime(startDate), 1000);
    return () => clearInterval(interval);
  }, [getTime, startDate]);



  return (
    <div>
        <h2>
            {getTimeText(minutes, seconds, hours)[0]}
        </h2>
    </div>
  );
};

export default Timer;