import React, {useState, useEffect} from 'react';
import { StyledSettings, Container, Main, Company, Button } from '../styled/Settings'; 
import { Menu } from "../styled/Admin";
import MenuComponent from "../component/MenuComponent";
import { useQuery, useMutation } from "@apollo/client";
import {UserParameters} from '../interfaces/User';
import { useNavigate } from 'react-router-dom';
import {getFromLocalStorage, removeFromLocalStorage} from '../gql/localStorage/store';
import {GET_OWNER} from '../gql/query/getOwner';
import {GET_WORKER} from '../gql/query/getWorker';
import {EDIT_OWNER} from '../gql/mutation/editOwner';
import { Form, Input } from 'antd';


interface Owner {
    getOwner: {
        _id: string,
        email: string,
        fullName: string,
        institutions: {
            _id: string,
            name: string
        }[],
        isNotApproved: boolean,
        nameOfTheOrganization: string,
        phoneNumber: string,
        photoURL: {
            M: string,
            XL: string,
            thumbnail: string 
        },
        token: string,
        typeOfBusiness: string
    }
};

interface EditedOwner {
    getOwner: {
        email: string,
        phoneNumber: string
    }
};

interface UserAdmin extends UserParameters {
    phoneNumber?: string
}

interface Worker {
    _id: string,
    fullName: string,
    institution: {
        _id: string,
        name: string
    } | null,
    login: string,
    token: string | null
}

const Settings = (props) => {

    const {data: owner} = useQuery<Owner>(GET_OWNER); 
    const {data: worker} = useQuery<Worker>(GET_WORKER);

    const [editOwner, {data: editedOwner}] = useMutation<EditedOwner>(EDIT_OWNER);


    const [user, setUser] = useState<UserAdmin>(
        getFromLocalStorage('JWT') ? 
        {
            login: JSON.parse(getFromLocalStorage('cred'))?.login,
            password: JSON.parse(getFromLocalStorage('cred'))?.password,
            phoneNumber:  owner ? owner.getOwner.phoneNumber : ''
        } : 
        {
            login: JSON.parse(getFromLocalStorage('cred'))?.login,
            password: JSON.parse(getFromLocalStorage('cred'))?.password,
        }
    );    

    useEffect(() => {

        if (owner) {
            setUser({
                ...user,
                phoneNumber: owner.getOwner.phoneNumber
            });
        }
        
        if (worker) {
            setUser({
                ...user
            });
        }


    }, [owner, worker]);

    const changeUserCred = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [event.target.name] : event.target.value
        });
    };

    const navigate = useNavigate();

    return <StyledSettings>
        <Menu>
            <MenuComponent/>
        </Menu>
        <Container>

            <h1>Настройки</h1>
            <Main>
                <h2>Главное</h2>
                <Form>
                    <Input
                        placeholder="Электронная почта"
                        name="login"
                        // defaultValue={user.login}
                        value={user.login}
                        type="text"
                        bordered
                        onChange={(e) => changeUserCred(e)}
                    />
                    <Input
                        placeholder="Пароль"
                        // defaultValue={user.password}
                        value={user.password}
                        name="password"
                        type="password"
                        bordered
                        onChange={(e) => changeUserCred(e)}
                    />
                    {getFromLocalStorage('JWT') && <Input
                        placeholder="Номер телефона"
                        // defaultValue={user.phoneNumber}
                        value={user.phoneNumber}
                        name="phoneNumber"
                        type="text"
                        bordered
                        onChange={(e) => changeUserCred(e)}
                    />}
                    <Button
                        active={''}
                        disabled={props.login === user.login && props.password === user.password}
                    >    
                        Сохранить изменения
                    </Button>
                </Form>
            </Main>

            <Button style={{marginTop: '30px'}} active={'logout'} onClick={() => {
                    getFromLocalStorage('JWT') ? removeFromLocalStorage("JWT") : getFromLocalStorage('WJWT');
                    removeFromLocalStorage("cred");
                    navigate('/login');
                }
            }>
                Выйти
            </Button>
        </Container>
    </StyledSettings>

}


export default Settings;