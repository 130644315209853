import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getFromLocalStorage } from "./localStorage/store";
import { SubscriptionClient } from "subscriptions-transport-ws";

const httpLink = new HttpLink({
  uri: "https://admin.timeapp.kz/payperminute",
  headers: {
    Authorization: getFromLocalStorage("JWT") || getFromLocalStorage("WJWT"),
  },
});

const wsLink = new WebSocketLink(
  new SubscriptionClient("wss://admin.timeapp.kz/payperminute", {
    connectionParams: {
      Authorization: getFromLocalStorage("JWT") || getFromLocalStorage("WJWT"),
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const createApolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
