import {gql} from '@apollo/client';


export const END_SESSION_ADMIN = gql`    
    mutation($sessionId: String!, $clientId: String!) {
        endSessionForAdmin(sessionId: $sessionId, clientId: $clientId) {
            _id,
            endTime,
            price,
            startTime,
            status,
            institution {
                _id,
                name,
                location {
                    address
                }
            },
            service {
                _id,
                price,
                bufferTimeMinutes,
            
            }
        }
    }
`;
