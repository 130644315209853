import {gql} from '@apollo/client';


export const EDIT_OWNER = gql`

    mutation($email: String, $newPassword: String){

        getOwner(email: $email, newPassword: $newPassword){
            email,
            phoneNumber
        }

    }

`;