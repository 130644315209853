import { useCallback, useEffect, useState } from "react"

export const MoneyCounter = ({ startDate, pricePerMinute, priceLimit }) => {
    const [price, setPrice] = useState(0);

    const getPrice = useCallback((startDate: string, pricePerMinute: number, priceLimit?: number) => {
        console.log(priceLimit);
        const time =  new Date(new Date().toISOString()).getTime() - Date.parse(startDate);
        const minutes = Math.round(time / (1000 * 60));
        const rawPrice = pricePerMinute * minutes;
        const price = priceLimit != null && rawPrice > priceLimit ? priceLimit : rawPrice;
        setPrice(price);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => getPrice(startDate, pricePerMinute, priceLimit), 1000);
        return () => clearInterval(interval);
    }, [getPrice, startDate, pricePerMinute, priceLimit])

    return (
        <div>
            <h2>
                {`${price}₸`}
            </h2>
        </div>
    );
}

export default MoneyCounter;