import React, { useEffect, useState } from "react";
import { UList, List } from "../styled/Admin";
import { NavLink, Link } from "react-router-dom";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const MenuComponent = () => {
  const { i18n, t } = useTranslation();

  const [lang, setLang] = useState(() =>
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "ru"
  );
  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    localStorage.setItem("lang", lang_code);
    setLang(lang_code);
  };
  useEffect(() => {
    if (!lang) return;
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <UList>
      <List>
        <NavLink
          end
          to="/admin"
          className={(navData) => (navData.isActive ? "activeLink" : "")}
        >
          <svg
            width="26"
            height="28"
            viewBox="0 0 26 28"
            fill="#979797"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.5 10.9711C0.5 10.0175 0.953315 9.12074 1.72115 8.55533L13 0.25L24.2789 8.55533C25.0467 9.12074 25.5 10.0175 25.5 10.9711V25.25C25.5 26.6307 24.3807 27.75 23 27.75H17.75C17.1977 27.75 16.75 27.3023 16.75 26.75V18.875C16.75 18.5989 16.5261 18.375 16.25 18.375H9.75C9.47386 18.375 9.25 18.5989 9.25 18.875V26.75C9.25 27.3023 8.80229 27.75 8.25 27.75H3C1.61929 27.75 0.5 26.6307 0.5 25.25V10.9711Z" />
          </svg>
        </NavLink>
      </List>
      {/* <List>
                <NavLink to='/admin/object-info' className={(navData) => navData.isActive ? 'activeLink' : ''}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.375" y="0.375" width="10" height="10" rx="1" fill="#979797"/>
                        <rect x="15.625" y="0.375" width="10" height="10" rx="1" fill="#979797"/>
                        <rect x="0.375" y="15.625" width="10" height="10" rx="1" fill="#979797"/>
                        <rect x="15.625" y="15.625" width="10" height="10" rx="1" fill="#979797"/>
                    </svg>
                </NavLink>
            </List> */}
      <List>
        <NavLink
          to="/admin/stats"
          className={(navData) => (navData.isActive ? "activeLink" : "")}
        >
          <svg
            width="30"
            height="28"
            viewBox="0 0 30 28"
            fill="#979797"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2902_11849)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.6875 0.0244141H19.6875V1.77856C19.6875 2.46893 20.2471 3.02856 20.9375 3.02856C21.6279 3.02856 22.1875 2.46893 22.1875 1.77856V0.0244141H25.2849C27.356 0.0244141 29.0349 1.70335 29.0349 3.77441V6.77856C29.0346 6.77856 29.0344 6.77856 29.034 6.77856H0.341797V3.77441C0.341797 1.70334 2.02072 0.0244141 4.0918 0.0244141H7.1875V1.77856C7.1875 2.46893 7.74715 3.02856 8.4375 3.02856C9.12785 3.02856 9.6875 2.46893 9.6875 1.77856V0.0244141ZM0.341797 9.27856V23.5329C0.341797 25.6039 2.02072 27.2829 4.0918 27.2829H25.2849C27.356 27.2829 29.0349 25.6039 29.0349 23.5329V9.27856C29.0346 9.27856 29.0344 9.27856 29.034 9.27856H0.341797ZM9.0625 13.0286C8.37215 13.0286 7.8125 13.5883 7.8125 14.2786C7.8125 14.9689 8.37215 15.5286 9.0625 15.5286H11.5625C12.2528 15.5286 12.8125 14.9689 12.8125 14.2786C12.8125 13.5883 12.2528 13.0286 11.5625 13.0286H9.0625ZM16.5625 14.2786C16.5625 13.5883 17.1221 13.0286 17.8125 13.0286H20.3125C21.0029 13.0286 21.5625 13.5883 21.5625 14.2786C21.5625 14.9689 21.0029 15.5286 20.3125 15.5286H17.8125C17.1221 15.5286 16.5625 14.9689 16.5625 14.2786ZM9.0625 18.0286C8.37215 18.0286 7.8125 18.5883 7.8125 19.2786C7.8125 19.9689 8.37215 20.5286 9.0625 20.5286H11.5625C12.2528 20.5286 12.8125 19.9689 12.8125 19.2786C12.8125 18.5883 12.2528 18.0286 11.5625 18.0286H9.0625ZM16.5625 19.2786C16.5625 18.5883 17.1221 18.0286 17.8125 18.0286H20.3125C21.0029 18.0286 21.5625 18.5883 21.5625 19.2786C21.5625 19.9689 21.0029 20.5286 20.3125 20.5286H17.8125C17.1221 20.5286 16.5625 19.9689 16.5625 19.2786Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_2902_11849">
                <rect width="30" height="27.5" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </NavLink>
      </List>
      <List style={{ padding: "20px 0" }}>
        <NavLink
          to="/admin/settings"
          className={(navData) => (navData.isActive ? "activeLink" : "")}
        >
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="#979797"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.7152 2.1126C15.9386 0.816633 14.0607 0.816634 13.2841 2.1126L11.7824 4.61869C11.3295 5.37449 10.4353 5.74486 9.58064 5.53068L6.74667 4.82049C5.28115 4.45324 3.95324 5.78115 4.32049 7.24667L5.03068 10.0806C5.24486 10.9353 4.87449 11.8295 4.11869 12.2824L1.6126 13.7841C0.316633 14.5607 0.316634 16.4386 1.6126 17.2152L4.11869 18.717C4.87449 19.1699 5.24486 20.064 5.03068 20.9187L4.32049 23.7527C3.95324 25.2182 5.28115 26.5461 6.74667 26.1788L9.58064 25.4687C10.4353 25.2545 11.3295 25.6248 11.7824 26.3806L13.2841 28.8867C14.0607 30.1827 15.9386 30.1827 16.7152 28.8867L18.217 26.3806C18.6699 25.6248 19.564 25.2545 20.4187 25.4687L23.2527 26.1788C24.7182 26.5461 26.0461 25.2182 25.6788 23.7527L24.9687 20.9187C24.7545 20.064 25.1248 19.1699 25.8806 18.717L28.3867 17.2152C29.6827 16.4386 29.6827 14.5607 28.3867 13.7841L25.8806 12.2824C25.1248 11.8295 24.7545 10.9353 24.9687 10.0806L25.6788 7.24667C26.0461 5.78115 24.7182 4.45324 23.2527 4.82049L20.4187 5.53068C19.564 5.74486 18.6699 5.37449 18.217 4.61869L16.7152 2.1126ZM14.9997 20.4997C17.7611 20.4997 19.9997 18.2611 19.9997 15.4997C19.9997 12.7382 17.7611 10.4997 14.9997 10.4997C12.2382 10.4997 9.99967 12.7382 9.99967 15.4997C9.99967 18.2611 12.2382 20.4997 14.9997 20.4997Z"
            />
          </svg>
        </NavLink>
      </List>
      <List style={{ padding: "20px 0" }}>
        <select defaultValue={lang} onChange={onChangeLang}>
          <option value={"ru"}>РУС</option>
          <option value={"en"}>ENG</option>
        </select>
      </List>
    </UList>
  );
};

export default MenuComponent;
