import React, {useState} from 'react';
import {Gallery} from '../pages/InstitutionInfo';
import left from '../assets/icons/left-pag.svg';
import right from '../assets/icons/right-pag.svg';

interface ImageSlider {
    images: Gallery[];
}

const ImageSlider = ({images}: ImageSlider) => {

    const [imagePage, setImagePage] = useState(0);


    return (
        <div className="image-pagination">

            <div className="pagination-buttons">
                <button onClick={() => {

                    if (imagePage + 1 > 0) {
                        setImagePage(prev => prev - 1);
                    }
                }
                }>
                    <img src={left} alt=""/>
                </button>

                <button onClick={() => {
                    if (imagePage + 1 < images.length) {
                        setImagePage(prev => prev + 1);
                    }
                }}>
                    <img src={right} alt=""/>
                </button>
            </div>

            <img className="main-image" src={images[imagePage].XL} alt=""/>
        </div>
    );
}


export default ImageSlider;