import React from 'react';
import MenuComponent from "../component/MenuComponent";
import { Menu } from "../styled/Admin";
import {StyledCreateInst, CreateContainer, Button} from '../styled/CreateInst';
import arrow from '../assets/icons/back-arrow.svg';




const CreateInst = () => {


    return (


        <StyledCreateInst>
            <Menu>
                <MenuComponent/>
            </Menu>
            <CreateContainer>
                <div className="save-create">

                    <div className="create-header">

                        <div className="back">
                            <img src={arrow} alt=""/>
                            Назад
                        </div>

                        <h2>Добавление нового объекта</h2>
                    </div>
                    <Button>
                        Сохранить
                    </Button>
                </div>

                <div>


                    <h1>Введите название объекта</h1>

                </div>

            </CreateContainer>
        </StyledCreateInst>
    );

}


export default CreateInst;