import styled from 'styled-components';



export const StyledCreateInst = styled.div`

    display: flex;

`;






export const CreateContainer = styled.div`

    width: 100%;
    margin-top: 15px;

    .save-create {

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 15px;
        background: #FFFFFF;
        box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.04);
        border-radius: 15px;



        .create-header {
            .back {
                display: flex;
                align-items: center;
                color: #007AFF;
            }
        }
    }
`;


export const Button = styled('button')`

    max-width: 155px;
    width: 100%;
    height: 60px;
    box-shadow: none;
    border: none;
    background: #F0F0F0;
    border-radius: 16px;

`