import React, { useEffect, useState, useRef } from 'react';
import {Visitations} from '../interfaces/Visitations';
import {Data, DataComponent, NameCapital} from '../styled/Admin';
import {DataItemInterface} from '../interfaces/DataItem';
import {useNavigate} from 'react-router-dom';
import {getTimeDifference} from '../utils/timeDifference';
import {onScroll} from '../utils/onScroll';
import Timer from './Timer';
import { MoneyCounter } from './MoneyCounter';
import Client from '../pages/Client';




const DataItem: React.FC<DataItemInterface> = ({sessions, isActive, getById, date, search, numberOfSessions, setPage}) => {

    const refToBottom = useRef<HTMLDivElement>(null);
    const [allSessions, setAllSession] = useState<Visitations[]>([]);

    const navigate = useNavigate();

    const resetSession = () => {

        if(sessions.length !== 0) {
            
            for(const session of sessions)
            {
                const res = allSessions.filter((item) => ((item._id === session._id)));
                if (res.length === 0) {
                    allSessions.push(session);
                }
            }
            isActive ? setAllSession(allSessions.filter((item) => item.endTime === null && item.status === 'Ongoing')): setAllSession(allSessions.filter((item) => item.endTime !== null));
        }
        else setAllSession([...sessions]);
    };
    
    useEffect(() => {
        resetSession();
    }, [isActive, sessions]);


    useEffect(() => {
        if (search !== '') {
            const res = allSessions.filter((item) => item.client.fullName.toLowerCase().includes(search.toLowerCase()));
            setAllSession(res);
        }
        else {
            resetSession();
        }

    }, [search]);


    
    return (
        <Data
            ref={refToBottom}
            onScroll={() => onScroll(refToBottom, sessions, setPage)}	         
        >
            {sessions.map((item) => {
                const id = item._id;
                const shortId = id.substr(1, 5).toUpperCase() + '-' + id.substr(id.length - 2).toUpperCase();
                const startDate = new Date(item.startTime);
                const hours = startDate.getHours();
                const minutes = startDate.getMinutes();
                const startTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
                const [timeDiff, minutesspent] = getTimeDifference( isActive ? (new Date()).toISOString() : item.endTime, item.startTime);

                let moneybepaid = 0;
                if (item.service && minutesspent > item.service?.bufferTimeMinutes) {
                    moneybepaid += (item.service.price)*(Number(minutesspent) - item.service.bufferTimeMinutes);
                }
                const num = item.client.phoneNumber ? `+${item.client.phoneNumber[0]} ${item.client.phoneNumber.substr(1, 3)} ${item.client.phoneNumber.substr(4, 3)} ${item.client.phoneNumber.substr(7, 2)} ${item.client.phoneNumber.substr(9, 2)}` : '';
                if (item.institution._id=== getById || getById === 'all') {
                    return <React.Fragment key={item._id}>
                        <DataComponent className="data-component" onClick={() => navigate(`${item._id}`, {state: {...item.client}})}>
                            <div className="leed-show">
                                <div className="company-name">
        
                                    <div className="institution-name-header">
                                        <span>{item.institution.name}</span>
                                    </div>
                                    <div className="start-time">
                                        <span>{startTime} {date}</span>
                                    </div>
                                </div>
                                <div className="leed">
                                    <div className="personal-data">
                                        <div className="data">
                                            {item.client.photoURL ?
                                                <img
                                                    width={"53px"}
                                                    height={"53px"}
                                                    style={{
                                                        borderRadius: '50%',
                                                        margin: '0 15px'
                                                    }}
                                                    src={item.client.photoURL.M}
                                                />
                                            : 
                                            <NameCapital 
                                                active={isActive}
                                                fontSize={31}
                                            >
                                                {item.client.fullName[0].toUpperCase()}
                                            </NameCapital>
                                            }
                                            <div className="client-name">
                                                <h2>{item.client.fullName}</h2>
                                                <p>{num}</p>
                                            </div>
                                        </div>
                                        <div className="instituion-name">
                                            <div className="short-id">{shortId}</div>
                                            <div>{item.institution.name}</div>
                                            <div>{item.service?.name}</div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="money-spent">
                                            {item.status == "Ongoing" ? <Timer startDate={item.startTime}/> : <div>
                                                <h2>
                                                    {timeDiff}                          
                                                </h2>
                                            </div> }
                                            
                                            {/* <div>
                                                <h2>
                                                    {timeDiff}                          
                                                </h2>
                                            </div> */}
                                            {
                                                item.status == "Ongoing" ? <MoneyCounter startDate={item.startTime} pricePerMinute={item.service?.price} priceLimit={item.service?.maxPrice}/> : <div>
                                                    <h2>
                                                        {`${item.price}₸`}
                                                    </h2>
                                                </div>
                                            }
                                            {/* <div>
                                                <h2>{item.price !== undefined && item.price !== null ? item.price : moneybepaid}₸</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="time-spent-responsive">
                                <div className="money-spent">
                                    <div>
                                        <h2>
                                            {timeDiff}                                   
                                        </h2>
                                    </div>
                                    <div>
                                        <h2>{item.price !== undefined && item.price !== null ? item.price : moneybepaid}₸</h2>
                                    </div>
                                </div>
                            </div>
                        </DataComponent>
                    </React.Fragment>
                }
            })}

		</Data>

    );
}


export default DataItem;