import {gql} from '@apollo/client';


export const GET_OWNER = gql`
    query {
        getOwner{
            _id,
            email,
            fullName,
            institutions {
                _id,
                name
            },
            isNotApproved,
            nameOfTheOrganization,
            phoneNumber,
            photoURL {
                M,
                XL,
                thumbnail 
            },
            token,
            typeOfBusiness
            
        }
    }
`;